.hour-area{
    display: flex;
    padding : 1%;
    flex-wrap: wrap;
}

.hour-item{
    width: 9%;
    height: 50px;
    background-color: lightseagreen;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item-disabled{
    width: 9%;
    height: 50px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item-update{
    width: 9%;
    height: 50px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    margin: 1% 2% 0 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.hour-item span{
    color: white;
    font-weight: bold;
}

.please-text{
    margin: 1%;
    font-weight: bold;
    color: black;
    font-style: italic;
    font-size: 14px;
}

.loading-div{
    display: flex;
    justify-content: center;
}

.hour-item:hover{
    background-color: black;
}

.hour-item span:hover{
    background-color: black;
    cursor: pointer;
}

.doctor-calendar-available-day {
    color: #0785c7;
    font-size: large;
}

/**responsive css kodlari*/
@media only screen and (max-width: 992px) {
    .hour-item{
        width: 12%;
    }
    .hour-item-disabled{
        width: 12%;
    }
}

@media only screen and (max-width: 768px) {
    .hour-area{
        justify-content: center;
    }
    .hour-item{
        width: 25%;
    }
    .hour-item-disabled{
        width: 25%;
    }
}
